import React from "react"
import "../styles/index.css"
import { Helmet } from "react-helmet"
import NowPlaying from "../components/nowplaying"
// import { LastFmData } from '../components/lastFM';


export default () => (
  <div>
    <Helmet>
     <meta charSet="utf-8" name="robots" content="noindex" name="theme-color" content="#000" />
      <title>🌴 gs / xyz</title>
      <link rel="canonical" href="https://gagansingh.xyz" />
      <script src="https://kit.fontawesome.com/6b889176de.js" crossorigin="anonymous"></script>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    </Helmet>
    <div className="homePage">
    🌴🌴🌴
    <br/><br/><br/>
    <a href="https://read.cv/gagan" className="me">gagan singh</a><NowPlaying /><br/>
    {/* <br/><div className="bio">
    
    </div><br/><br/><br/> */}
    <br/><br/>
    <a href="http://twitter.com/gdsingh" className="social"><i class="fab fa-twitter"></i></a>&nbsp;&nbsp;
    <a href="http://instagram.com/gdsingh" className="social"><i class="fab fa-instagram"></i></a>
    {/* <p>skip ahead™️ ✌🏽 is a <a href="https://gagansingh.substack.com">substack ↗</a>, do what you'd like with that.</p> */}
    <br />
    <br />
    <br />
    {/* <a href="/archive">archive</a>&nbsp;&nbsp; */}
    <a href="https://gagansingh.substack.com">substack</a>&nbsp;&nbsp;
    <a href="https://visuals.gagansingh.xyz/">visuals ↗</a>
    <br /><br /><br /><br /><br />
    <div className="top-bar"><a href="/">☺</a></div>
    </div>
  </div>
)