import React from "react"
import { useEffect, useState } from "react";
import getNowPlayingItem from "./spotifyapi"

function SpotifyPlayer(props) {
  const [result, setResult] = useState({});

  useEffect(() => {
    Promise.all([
      getNowPlayingItem(
        props.client_id,
        props.client_secret,
        props.refresh_token
      ),
    ]).then((results) => {
      setResult(results[0]);
    });
  }, []);

  return result.isPlaying ? (
    <p className="now-playing">is listening to <i class="fab fa-spotify" style={{color: "#FFF"}}></i> <a href={result.songUrl} target="_blank" style={{textTransform: "uppercase"}}>{result.title} by {result.artist}</a></p>
  ) : (
    <p className="now-playing">&nbsp;</p>
    // <i class="fab fa-spotify" style={{color: "#000"}}></i> not spinnin' anything...
  );
}

export default SpotifyPlayer;